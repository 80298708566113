function getAPIURI() {
  console.log(`node env is ${process.env.NODE_ENV}`);
  if (process.env.NODE_ENV === "development")
    return process.env.VUE_APP_API_URI_DEV;
  else if (process.env.NODE_ENV === "production")
    return process.env.VUE_APP_API_URI_PROD;
  else return process.env.VUE_APP_API_URI_TEST;
}

function getAppURI() {
  if (process.env.NODE_ENV === "development")
    return process.env.VUE_APP_URI_DEV;
  else if (process.env.NODE_ENV === "production")
    return process.env.VUE_APP_URI_PROD;
  else return process.env.VUE_APP_URI_TEST;
}

function getStripeKey() {
  if (process.env.NODE_ENV === "development")
    return process.env.VUE_APP_STRIPE_KEY_DEV;
  else if (process.env.NODE_ENV === "production")
    return process.env.VUE_APP_STRIPE_KEY_PROD;
  else return process.env.VUE_APP_STRIPE_KEY_DEV;
}

const API_URI = getAPIURI();
const STRIPE_KEY = getStripeKey();
const APP_URI = getAppURI();

export { API_URI, STRIPE_KEY, APP_URI };
