<template>
  <div>
    <b-jumbotron>
      <b-container>
        <h2>{{ status }}</h2>

        <h2>{{ eta }}</h2>
      </b-container>
    </b-jumbotron>
    <b-container v-show="false">
      <b-row>
        <b-col>
          <b-button size="lg" variant="primary"> Go back </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped></style>
<script>
export default {
  name: "CompleteSignupRequest",
  computed: {
    eta() {
      const { request } = this;

      if (request.completedBy) {
        return 'Enjoy your code review!'
      }

      if (request) {
        Date.prototype.addHours = function (h) {
          this.setHours(this.getHours() + h);
          return this;
        };

        const createdAt = new Date(request.createdAt);
        const eta = createdAt.addHours(24 * 3);
        const etaString = `${eta.toLocaleDateString()} at ${eta.toLocaleTimeString()}`;
        return `Delivery estimated by ${etaString}`;
      }

      return "";
    },
    status() {
      const { request } = this;
      if (request) {
        const completedAt = new Date(request.completedAt);
        const completedAtString = `${completedAt.toLocaleDateString()} at ${completedAt.toLocaleTimeString()}`;

        const claimedAt = new Date(request.claimedAt);
        const claimedAtString = `${claimedAt.toLocaleDateString()} at ${claimedAt.toLocaleTimeString()}`;

        if (request.claimedAt) {
          if (request.completedAt) {
            return `Completed on ${completedAtString}`;
          } else {
            return `Claimed on ${claimedAtString}`;
          }
        } else {
          return "Matching with a Reviewer";
        }
      }

      return "";
    },
  },
  data: () => {
    return {
      request: null,
      loading: false,
    };
  },
  /**
   * Remaining Todos:
   * 1. Add State Check
   * 2. Create reusable component for router redirection and toast
   * 3. Figure out wsup with a vue directive toast tho breh breh
   */
  async mounted() {
    this.loading = true;

    //todo- add state check
    const request = sessionStorage.getItem("request");

    this.request = JSON.parse(request);

    this.loading = false;
  },
};
</script>
