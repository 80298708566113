import { API_URI } from "@/helpers/env_helper.js";

/**
 * TODO- complete re-usable request to dry up code.
 * @param {*} url
 * @param {*} method
 * @param {*} body
 
const request = async (url, method, body) => {
  // TODO - DRY up code into request function
};
*/

/**
 * Completes the Github auth flow
 *
 * @param {String} code
 * @returns boolean
 */
const completeSignup = async (code) => {
  console.log(code);
  const tokenFetch = await fetch(`${API_URI}/complete-signup`, {
    method: "POST",
    body: JSON.stringify({ code, isReviewer: true }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const tokenJSON = await tokenFetch.json();
  return tokenJSON;
};

/**
 * Gets the current authenticated user.
 *
 * @returns user object
 */
const getUser = async () => {
  const token = sessionStorage.getItem("token");

  const userFetch = await fetch(`${API_URI}/user`, {
    method: "POST",
    body: JSON.stringify({ token }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const userJSON = await userFetch.json();
  return userJSON;
};

/**
 * Gets repositories owned by a user.
 *
 * @returns {Object}
 */
const getUnclaimedRequests = async () => {
  const token = sessionStorage.getItem("token");
  const user = sessionStorage.getItem("login");
  const dataFetch = await fetch(`${API_URI}/unclaimed-review-requests`, {
    method: "POST",
    body: JSON.stringify({ token, user }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const dataJSON = await dataFetch.json();
  return dataJSON.reviewRequests;
};

/**
 *
 * @param {*} repo
 * @param {*} owner
 * @returns
 */
const claimRequest = async (request) => {
  const token = sessionStorage.getItem("token");
  const user = sessionStorage.getItem("login");
  const dataFetch = await fetch(`${API_URI}/claim-review-request`, {
    method: "POST",
    body: JSON.stringify({
      token,
      user,
      request,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const dataJSON = await dataFetch.json();
  return dataJSON.requests;
};

/** CLEAN UP : */

/**
 * Gets repo details including language and pull requests
 */
const getRepoDetails = async (repo, owner) => {
  console.log(`repo: ${repo} owner: ${owner}`);
  const token = sessionStorage.getItem("token");

  const repoDetailsFetch = await fetch(`${API_URI}/repo-details`, {
    method: "POST",
    body: JSON.stringify({
      token,
      owner: owner,
      repo: repo,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const repoDetailsJSON = await repoDetailsFetch.json();
  return repoDetailsJSON.data;
};

/**
 * Set Focus -
 */
const setFocusList = async (owner, focusList) => {
  const token = sessionStorage.getItem("token");

  const setFocusFetch = await fetch(`${API_URI}/set-focus-repo`, {
    method: "POST",
    body: JSON.stringify({
      token,
      owner: owner,
      focusList: focusList,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const setFocusJSON = await setFocusFetch.json();
  return setFocusJSON.data;
};

/**
 * Update Focus -
 *
 * TODO- make a request to persist focus repos with owner/ repo name
 */

/**
 * Get Focus -
 *
 * TODO- make a request to retrieve focus repos
 */
const getFocusList = async (owner) => {
  const token = sessionStorage.getItem("token");

  const getFocusFetch = await fetch(`${API_URI}/get-focus-list`, {
    method: "POST",
    body: JSON.stringify({
      token,
      owner,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const getFocusJSON = await getFocusFetch.json();
  return getFocusJSON;
};

/**
 * Creates a review request
 */
const createReviewRequest = async (codeReviewPurchaseInfo) => {
  const token = sessionStorage.getItem("token");

  const createReviewRequestFetch = await fetch(
    `${API_URI}/create-review-request`,
    {
      method: "POST",
      body: JSON.stringify({
        purchaser: codeReviewPurchaseInfo.purchaser,
        pr: codeReviewPurchaseInfo.pr,
        repo: codeReviewPurchaseInfo.repo,
        token,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const createReviewRequestJSON = await createReviewRequestFetch.json();
  return createReviewRequestJSON;
};

/**
 *
 * @param {*} user
 * @returns
 */
const completeReviewRequest = async (request) => {
  const token = sessionStorage.getItem("token");
  const user = sessionStorage.getItem("login");
  const fetchData = await fetch(`${API_URI}/complete-review-request`, {
    method: "POST",
    body: JSON.stringify({
      request,
      token,
      user,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const JSONData = await fetchData.json();
  return JSONData;
};

/**
 * Gets review requests for the user
 */
const getUserClaimedReviewRequests = async () => {
  const token = sessionStorage.getItem("token");
  const user = sessionStorage.getItem("login");
  const getReviewRequestsFetch = await fetch(
    `${API_URI}/get-reviewer-review-requests`,
    {
      method: "POST",
      body: JSON.stringify({
        user,
        token,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const getReviewRequestsJSON = await getReviewRequestsFetch.json();
  return getReviewRequestsJSON;
};

export {
  getUser,
  getUnclaimedRequests,
  getRepoDetails,
  setFocusList,
  claimRequest,
  getFocusList,
  completeSignup,
  createReviewRequest,
  completeReviewRequest,
  getUserClaimedReviewRequests,
};