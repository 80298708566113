<template>
  <div class="home">
    <div class="focus">
      <div v-if="signedIn">
        <b-jumbotron
          :header="`Welcome ${signedIn}`"
          :lead="
            revisionRequests.length > 0
              ? `${revisionRequests.length} pending reviews`
              : 'Claim a new  request'
          "
        >
          <b-button variant="primary" @click="goToRequests">
            Get a New Code Review</b-button
          >
        </b-jumbotron>
        <div v-if="loading" class="d-flex justify-content-center mb-3">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else>
          <b-container class="bv-example-row">
            <b-row>
              <b-col>
                <h2>Claimed Revision Requests</h2>
                <div v-if="revisionRequests && revisionRequests.length > 0">
                  <b-row
                    v-for="request in revisionRequests"
                    :key="request.id"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <b-col>
                      {{ request.pr.title }} from {{ request.repo }}
                    </b-col>
                    <b-col>
                      <b-badge variant="primary" pill>
                        {{ timeToComplete(request) }}
                      </b-badge>
                    </b-col>
                    <b-col>
                      <b-button @click="openGithub(request)">
                        Review On
                        <b-icon icon="github" />
                      </b-button>
                      <b-button @click="markAsComplete(request)">
                        Mark as Complete
                        <b-icon
                          icon="arrow-right-short"
                          font-scale="1.5"
                          @click="goToRequest(request)"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <b-button> Claim a Request </b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
      <div v-else>
        <b-jumbotron
          fluid
          bg-variant="dark"
          text-variant="light"
          header="Earn Side Income Reviewing Code."
          lead="Get a code review from a senior software engineer directy on your github repository  in under 48 hours."
        >
          <b-button variant="primary" @click="login">
            Login with <b-icon-github />
          </b-button>
        </b-jumbotron>

        <b-container>
          <b-row>
            <b-col>
              <h3>
                Speed past bugs, busy mentor schedules, and gaps in the
                education system with these 4 simple steps
              </h3>
            </b-col>
          </b-row>

          <b-row align-v="center">
            <b-col sm="12" md="6">
              <img src="@/assets/get-a-review.jpg" class="img-fluid" />
            </b-col>

            <b-col sm="12" md="6">
              <div class="step-title">1</div>
              <div class="step">Choose the repository you're working on</div>
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col sm="12" md="6">
              <img src="@/assets/code-repo.jpg" class="img-fluid" />
            </b-col>

            <b-col>
              <div class="step-title">2</div>
              <div class="step">
                Choose the pull request you'd like reviewed
              </div>
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col sm="12" md="6">
              <img src="@/assets/code-review.jpg" class="img-fluid" />
            </b-col>

            <b-col>
              <div class="step-title">3</div>
              <div class="step">Request a code review</div>
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col sm="12" md="6">
              <img src="@/assets/code-projects.jpg" class="img-fluid" />
            </b-col>

            <b-col>
              <div class="step-title">4</div>
              <div class="step">Get your code review within 48 hours</div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <h2>Start your review in minutes!</h2>
              <b-button variant="primary" @click="login">
                Login with <b-icon-github />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.jumbotron {
  background: linear-gradient(rgba(42, 42, 42, 0.8), rgba(42, 42, 42, 0.8)),
    url("https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #242424 !important;
  color: white;
}

svg {
  &:hover {
    cursor: pointer;
  }
}

.step-title {
  font-size: 3rem;
  font-family: "Paytone One";
}

.step {
  font-size: 28px;
}
</style>

<script>
import { authenticationHelper } from "@/mixins/authentication.js";
import { completeReviewRequest } from "@/models/requests.js";

export default {
  name: "Home",
  mixins: [authenticationHelper],
  data: () => {
    return {
      loading: false,
    };
  },
  async mounted() {
    if (this.signedIn) {
      this.loading = true;
      await this.$store.dispatch("getReviewRequests");
      this.loading = false;
    }
  },
  computed: {
    signedIn() {
      return sessionStorage.getItem("login");
    },
    revisionRequests() {
      return this.$store.state.reviewRequests;
    },
  },
  methods: {
    /**
     * Marks request as complete
     */
    async markAsComplete(request) {
      await completeReviewRequest(request);
      await this.$store.dispatch("getReviewRequests");
    },
    /**
     *
     */
    timeToComplete(request) {
      const claimedDate = new Date(request.claimedAt);
      console.log(claimedDate);
      const now = new Date();
      claimedDate.setHours(claimedDate.getHours() + 3);

      const timeLeft = Math.round(
        (claimedDate.getTime() - now.getTime()) / (1000 * 60),
        2
      );

      if (timeLeft > 60) {
        return `${Math.floor(timeLeft / 60)} hours and ${
          timeLeft % 60
        } minutes left`;
      } else {
        return `${timeLeft} minutes left`;
      }
    },
    /**
     * Open on Github
     */
    openGithub(request) {
      window.open(request.pr.html_url, "_blank");
    },

    /**
     * Dispatches github login
     */
    login() {
      const clientID = process.env.VUE_APP_GITHUB_CLIENT;
      window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientID}&scope=repo%20repo:invite%20read:user%20user:email&state=yeeeyee`;
    },
    /**
     *
     */
    getDetailURL(repo) {
      return `/user/${repo.owner}/repo/${repo.name}`;
    },
    /**
     *
     */
    goToRequests() {
      this.$router.push("/requests");
    },
    /**
     * Redirects to request
     */
    goToRequest(request) {
      sessionStorage.setItem("request", JSON.stringify(request));
      this.$router.push(`/requests/${request.createdAt}`);
    },
    /**
     * Calculate status message of request
     */
    requestStatus(request) {
      if (request.completedBy) return "Revision Complete";
      else if (request.claimedBy && !request.completedBy)
        return "Revision In Progress";
      else return "Searching for reviewer";
    },
  },
  watch: {
    async signedIn(oldValue, newValue) {
      if (!oldValue && newValue) {
        this.$store.dispatch("getReviewRequests");
      }
    },
  },
};
</script>
