<template>
  <div id="requests">
    <div v-if="requests.length > 0">
      <div class="repository">
        <b-jumbotron>
          <h1>Claim a request</h1>
        </b-jumbotron>
      </div>

      <b-container class="list-box">
        <div class="data-box">
          <div
            class="row list-row"
            v-for="(request, index) in requests"
            :key="request.repo + index"
          >
            <div class="col">
              <div class="repo-name">{{ request.pr.title }}</div>
              <i class="repo-description">{{ "Add some data here" }}</i>
            </div>
            <div class="col childSpacingRight">
              <b-badge pill variant="success">
                Written in {{ mainLanguages(request) }}</b-badge
              >
              <b-badge pill variant="primary">
                {{ request.questions ? request.questions.length : 0 }} Questions
              </b-badge>
            </div>
            <div class="col" @click="startClaimRequest(request)">
              <b-button size="lg"> Claim this request </b-button>
            </div>
          </div>
          <b-button variant="primary" disabled v-if="loadingMore">
            <b-spinner small></b-spinner>
            Loading More...
          </b-button>
        </div>
      </b-container>
    </div>
    <div v-else>
      <div v-if="loading">
        <div class="d-flex justify-content-center mb-3">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
      <div v-else>No requests available. Check back in later.</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.jumbotron {
  background: linear-gradient(rgba(42, 42, 42, 0.8), rgba(42, 42, 42, 0.8)),
    url("https://images.pexels.com/photos/1342460/pexels-photo-1342460.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #242424 !important;
  color: white;
}

.childSpacingRight {
  > *:first-child {
    margin-right: 1rem;
  }
}
.repo-name {
  font-size: 22px;
  font-weight: bold;
  overflow-wrap: anywhere;
  &:hover {
    cursor: pointer;
  }
}

.repo-visibility {
  width: fit-content;
  border: 1px solid black;
  border-radius: 2rem;
  padding: 0.42rem;
}

.data-box {
  max-height: 70vh;
  overflow: scroll;
  padding: 1rem;
}
</style>
<script>
/**
 * Todo- Finish the design by separating two routes that pass different actions and call this
 */

import { getUnclaimedRequests, claimRequest } from "@/models/requests.js";
import { authenticationHelper } from "@/mixins/authentication.js";

export default {
  name: "requests",
  mixins: [authenticationHelper],
  data: () => {
    return {
      repoSearchTerm: "",
      requests: [],
      loading: false,
      loadingMore: false,
      page: 1,
    };
  },
  async created() {
    this.loading = true;
    this.requests = await getUnclaimedRequests();
    this.loading = false;
  },
  methods: {
    mainLanguages(request) {
      return request.languages[0].language;
    },
    /**
     * Returns boolean if the current user is owner of repo
     */
    isCurrentUserOwner(owner) {
      return sessionStorage.getItem("login") === owner;
    },
    /**
     * claimRequest
     */
    async startClaimRequest(request) {
      const res = await claimRequest(request);
      console.log(res);
    },
  },
};
</script>
