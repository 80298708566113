import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/pages/Home.vue";
import CompleteSignup from "@/pages/CompleteSignup.vue";
import Requests from "@/pages/Requests.vue";
import Repo from "@/pages/Repo.vue";
import RevisionRequest from "@/pages/RevisionRequest.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/requests",
    name: "Requests",
    component: Requests,
  },
  {
    path: "/complete-login",
    name: "CompleteSignup",
    component: CompleteSignup,
  },
  {
    path: "/user/:user/repo/:repo",
    name: "Repo",
    component: Repo,
  },
  {
    path: "/requests/:id",
    name: "Review Request Details",
    component: RevisionRequest,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
