<template>
  <b-row align-v="center" class="footer" v-show="!signedIn">
      <b-col col="2">
          <h2> Career Bars </h2>
          <div> Business inquiries: <a href="mailto:levelup@careerbars.com"> Email us </a> </div>
      </b-col>
  </b-row>
</template>
<style lang="scss" scoped>
@import "@/variables.scss";

.footer {
    margin-top: 1rem;
    padding: 2rem 0;
    background: $primary;
    color: $white;
}

a {
    color: $white;
}
</style>
<script>
export default {
  name: "Footer",

  computed: {
    signedIn() {
      return sessionStorage.getItem("login") || this.$store.state.user.login;
    },
  },
};
</script>
