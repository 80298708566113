<template>
  <div class="complete-signup">
    <h1>Logging you in</h1>
    <div class="d-flex justify-content-center mb-3">
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem; margin-right: 1rem"
        type="grow"
      ></b-spinner>
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem; margin-right: 1rem"
      ></b-spinner>
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem; margin-right: 1rem"
        type="grow"
      ></b-spinner>
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem; margin-right: 1rem"
      ></b-spinner>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.complete-signup {
  min-height: 100vh;
}
</style>
<script>
import { completeSignup, getUser } from "@/models/requests.js";

export default {
  name: "CompleteSignupRequest",
  data: () => {
    return {
      pulls: [],
      loading: false,
    };
  },
  /**
   * Remaining Todos:
   * 1. Add State Check
   * 2. Create reusable component for router redirection and toast
   * 3. Figure out wsup with a vue directive toast tho breh breh
   */
  async mounted() {
    this.loading = true;

    const code = this.$route.query.code;
    //const state = this.$route.params.state;
    if (!code) {
      this.$router.push("/");
      // add an alert here
    }
    //todo- add state check

    const tokenObject = await completeSignup(code);

    if (tokenObject.token) {
      sessionStorage.setItem("token", tokenObject.token);
      const user = await getUser();
      sessionStorage.setItem("login", user.user.login);
      this.$store.dispatch("persistUser", user);
      await this.$store.dispatch("getFocusList");
      if (this.$store.state.focus.length === 0) this.$router.push("/repos");
      else this.$router.push("/");
    } else {
      this.$router.push("/");
      // error message
    }

    this.loading = false;
  },
};
</script>
