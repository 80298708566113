<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      submit-type="book"
      @loading="(v) => (loading = v)"
    />

    <b-jumbotron>
      <h1>{{ this.$route.params.repo }} Details</h1>
      <b-button-group>
        <b-button
          v-for="language in languages"
          :size="language.percent > 50 ? 'lg' : 'md'"
          :key="language.language"
        >
          <b-badge>
            {{ language.percent != 0 ? language.percent : "1>" }} %
          </b-badge>
          {{ language.language }}
        </b-button>
      </b-button-group>
    </b-jumbotron>

    <div v-if="loadingPage" class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <b-container v-else>
      <div v-if="pulls.length > 0">
        <h2>Open Pull requests</h2>
        <b-row v-for="pr in pulls" :key="pr.id" class="list-row">
          <b-col>
            <h3>{{ pr.title }}</h3>
          </b-col>
          <b-col>
            <b-badge>Merging into {{ pr.base.ref }}</b-badge>
            <br />
            <b-badge>Created by {{ pr.user.login }}</b-badge>
          </b-col>
          <b-col>
            <b-button
              size="lg"
              variant="outline-primary"
              @click="getCodeReview(pr)"
              >Request Code Review</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <h3>We need a pull request to review</h3>
        Here's how you can make one:
        <br />
        1.
        <b-button variant="outline-primary" size="lg">
          <a
            :href="`https://github.com/${this.$route.params.user}/${this.$route.params.repo}`"
            target="_blank"
          >
            Create a new branch
          </a>
        </b-button>
        <a
          :href="'https://docs.github.com/en/github/collaborating-with-pull-requests/proposing-changes-to-your-work-with-pull-requests/creating-and-deleting-branches-within-your-repository'"
          target="_blank"
          class="primary"
        >
          <b-icon icon="question-circle-fill" size="lg"> </b-icon>
        </a>
        <br />
        Checkout that branch locally, make the code changes you need reviewed,
        and push up your code.
        <br />
        2.
        <b-button variant="outline-primary" size="lg">
          <a
            :href="`https://github.com/${this.$route.params.user}/${this.$route.params.repo}/compare`"
            target="_blank"
          >
            Create a new pull request
          </a>
        </b-button>
        <br />

        3.
        <b-button variant="outline-primary" size="lg">
          <a :href="refreshLink"> Refresh the page </a>
        </b-button>
      </div>

      <b-row v-show="false">
        <b-col v-if="branches.length > 0">
          <h3>Current Active Branches</h3>
          <div v-for="(branch, index) in branches" :key="branch.name + index">
            {{ index + 1 }}. {{ branch.name }}
          </div>
          <span v-if="branches.length < 2">
            Note: You will need to create a new branch with your code changes to
            make a PR!</span
          >
        </b-col>
        <b-col v-if="numberOfLanguages > 0">
          <h3>Main Languages used:</h3>
          <b-button-group>
            <b-button
              v-for="language in languages"
              :size="language.percent > 50 ? 'lg' : 'md'"
              :key="language.language"
            >
              <b-badge>
                {{ language.percent != 0 ? language.percent : "1>" }} %
              </b-badge>
              {{ language.language }}
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<style lang="scss" scoped>
.jumbotron {
  background: linear-gradient(rgba(42, 42, 42, 0.8), rgba(42, 42, 42, 0.8)),
    url("https://images.pexels.com/photos/249798/pexels-photo-249798.png?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #242424 !important;
  color: white;
}

button {
  margin: 0.5rem 0;
}
a {
  &:hover {
    color: white;
    text-decoration: none;
  }
}
</style>
<script>
/**
 * TODO- add create repo components from scratch later
 */
import { getRepoDetails } from "@/models/requests.js";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { authenticationHelper } from "@/mixins/authentication.js";
import { STRIPE_KEY, APP_URI } from "@/helpers/env_helper.js";

export default {
  name: "Repo",
  mixins: [authenticationHelper],

  components: {
    StripeCheckout,
  },
  data: () => {
    return {
      lineItems: [
        {
          price: "price_1J1nklJBhWZpn5tExJjostCa", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: `${APP_URI}/purchase-success`,
      cancelURL: `${APP_URI}/purchase-cancelled`,
      publishableKey: STRIPE_KEY,
      pulls: [],
      languages: [],
      branches: [],
      repo: {},
      loadingPage: false,
    };
  },
  async created() {
    if (this.$route.query.cancelled) {
      sessionStorage.removeItem("codeReviewPurchaseInfo");
    }

    this.loadingPage = true;
    const repo = this.$route.params.repo;
    const owner = this.$route.params.user;

    const repoDetails = await getRepoDetails(repo, owner);

    this.cancelURL = `${APP_URI}/user/${owner}/repo/${repo}?cancelled=true`;

    if (repoDetails.pulls) this.pulls = repoDetails.pulls;

    if (repoDetails.languages) this.languages = repoDetails.languages;

    if (repoDetails.branches) this.branches = repoDetails.branches;
    this.loadingPage = false;
  },
  computed: {
    numberOfLanguages() {
      return this.languages.length;
    },
    refreshLink() {
      return window.location.href;
    },
  },
  methods: {
    getCodeReview(pr) {
      const codeReviewInfo = {
        purchaser: this.$store.state.user.login,
        repo: this.$route.params.repo,
        repoOwner: this.$route.params.owner,
        languages: this.languages,
        pr,
      };

      sessionStorage.setItem(
        "codeReviewPurchaseInfo",
        JSON.stringify(codeReviewInfo)
      );
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>
